import store from '../store'

// eslint-disable-next-line no-unused-vars
export function beforeEach(to, from, next) {
    store.dispatch('showLoading')
    store.dispatch('auth/loadAuthFromLocalStorage')
    const token = store.state.auth.token
    if (to.name !== 'Login' && token == null) next({name: 'Login'})
    else if (to.name === 'Login' && token != null) next({name: 'Home'})
    else next()
}
