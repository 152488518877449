export default {
    path: '/logs',
    component: () => import(/* webpackChunkName: "logs" */ '../../views/panel/Logs'),
    children: [
        {
            path: '/',
            name: 'LogsList',
            meta: {
                'title': 'لاگ ها'
            },
            component: () => import('../../views/panel/Logs/List.vue'),
        },
        {
            path: ':id',
            component: () => import('../../views/panel/Logs/Profile/index.vue'),
            children: [
                {
                    path: '/',
                    name: 'LogProfile',
                    meta: {
                        'title': 'پروفایل لاگ'
                    },
                    component: () => import('../../views/panel/Logs/Profile/Profile.vue'),
                }
            ]
        },
    ]
}
