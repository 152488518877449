<template>
  <div class="error">
    <div class="error-box">
      <img src="../../assets/logo.png" alt="naserin">
      <h1>سامانه مدیریت گروه ناصرین</h1>
      <h3>مسجد قدس شهرستان نیشابور</h3>
      <p>جهت دسترسی به این سامانه باید </p>
      <p style="font-size: 23px;">با تلفن همراه وارد سامانه شوید.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "disable-desktop"
}
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .error[data-v-3dfd17c7] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .error-box {
    max-width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 0.75rem;
    padding: 20px;

    img {
      width: 100%;
      object-fit: contain;
      max-width: 200px;
    }

    h1 {
      margin: 20px 0 0;
      font-weight: 850;
      white-space: nowrap;
      font-size: 26px;
    }

    h3 {
      font-weight: 850;
      margin: 20px 0 0;
      font-size: 24px;
    }

    p {
      font-size: 22px;
      color: var(--red);
      margin: 20px 0 0;
      font-weight: 850;
    }
  }
}
</style>
