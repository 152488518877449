export default {
    path: '/transactions',
    component: () => import(/* webpackChunkName: "transactions" */ '../../views/panel/Transactions'),
    children: [
        {
            path: '/',
            name: 'TransactionsList',
            meta: {
                'title': 'لیست پرداخت ها'
            },
            component: () => import('../../views/panel/Transactions/List.vue'),
        },
        {
            path: 'insert',
            name: 'TransactionInsert',
            meta: {
                'title': 'افزودن پرداخت جدید'
            },
            component: () => import('../../views/panel/Transactions/Insert.vue'),
        },
        {
            path: ':id',
            component: () => import('../../views/panel/Transactions/Profile/index.vue'),
            children: [
                {
                    path: '/',
                    name: 'TransactionProfile',
                    meta: {
                        'title': 'پروفایل پرداخت'
                    },
                    component: () => import('../../views/panel/Transactions/Profile/Profile.vue'),
                },
                {
                    path: 'edit',
                    name: 'EditTransaction',
                    meta: {
                        'title': 'ویرایش پرداخت'
                    },
                    component: () => import('../../views/panel/Transactions/Edit.vue'),
                }
            ]
        },
    ]
}
