export default (page = 1, filters = [], sorts = []) => {
    let queries = [];

    if (page > 1) queries.push(`page=${page}`)

    sorts.forEach(sort => {
        queries.push(`order=${sort.key},${sort.type ?? 'asc'}`)
    })

    filters.forEach(filter => {
        queries.push(`${filter.key}=${filter.value}`)
    })

    queries = queries.join('&')
    return queries !== '' ? `?${queries}` : ''
}
