import priceFormat from "@/prototype/priceFormat";
import assetsUrlBuilder from "@/prototype/assetsUrlBuilder";
import makeToast from "@/prototype/makeToast";
import checkPermission from "@/prototype/checkPermission";

export default {
    makeToast(toast = {}) {
        return makeToast(toast)
    },
    price(number, currency = true) {
        return priceFormat(number, currency)
    },
    assetsUrlBuilder(url) {
        return assetsUrlBuilder(url)
    },
    checkPermission(permissions) {
        return checkPermission(permissions)
    }
}
