import http from '../../http'
import queryBuilder from '../queryBuilder';

export function getProfile() {
    return http.get('/v1/profile')
}

export function updateProfile(payload = {}) {
    return http.request({
        method: 'PATCH',
        url: '/v1/profile',
        data: payload
    })
}

export function getProfileTransactions(page = 1, filters= [], sorts = []) {
    const queries = queryBuilder(page, filters, sorts)
    return http.get(`/v1/profile/transactions${queries}`)
}

export function getProfileFamily(page = 1, filters= [], sorts = []) {
    const queries = queryBuilder(page, filters, sorts)
    return http.get(`/v1/profile/family${queries}`)
}
