export default {
    // profile
    getProfile: (state) => () => {
        if (state.profile) return state.profile
        else return null
    },

    // users
    getUserProfile: (state) => (userId) => {
        if (state.users[userId]) return state.users[userId]
        else return null
    },

    // groups
    getGroupProfile: (state) => (groupId) => {
        if (state.groups[groupId]) return state.groups[groupId]
        else return null
    },

    // families
    getFamilyProfile: (state) => (familyId) => {
        if (state.families[familyId]) return state.families[familyId]
        else return null
    },

    // religiousLeaders
    getReligiousLeaderProfile: (state) => (religiousLeaderId) => {
        if (state.religiousLeaders[religiousLeaderId]) return state.religiousLeaders[religiousLeaderId]
        else return null
    },

    // transactions
    getTransactionProfile: (state) => (transactionId) => {
        if (state.transactions[transactionId]) return state.transactions[transactionId]
        else return null
    }
}
