import router from "@/router";

export default {
    SET_TOKEN(state, token) {
        state.token = token
        if (token == null) localStorage.removeItem('token')
        else localStorage.setItem('token', token)
    },
    SET_USER(state, user) {
        state.user = user
        if (user == null) localStorage.removeItem('user')
        else localStorage.setItem('user', JSON.stringify(user))
    },
    LOGOUT(state) {
        state.token = null
        state.user = null
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        if (router.currentRoute.name !== 'Login') router.replace({name: 'Login'})
    }
}
