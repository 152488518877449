export default {
    // navbar
    getPageTitle(state) {
        return state.navbar.title
    },
    getRightActions(state) {
        return state.navbar.actions.right
    },
    getLeftActions(state) {
        return state.navbar.actions.left
    },

    // return to
    getReturnRoute(state) {
        return state.returnTo
    },

    // toasts
    getToasts(state) {
        return state.toasts
    }
}
