<template>
  <div class="n-toast">
    <div class="toast-box" :class="`toast-${color}`">
      <div class="toast-icon" v-if="icon">
        <b-icon :icon="icon"/>
      </div>
      <div class="toast-message">{{message}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "n-toast",
  props: {
    toast: {}
  },
  data() {
    return {
      icon: null,
      message: '',
      color: 'primary'
    }
  },
  created() {
    if (this.toast.icon) this.icon = this.toast.icon
    if (this.toast.message) this.message = this.toast.message
    if (this.toast.color && ['primary', 'success', 'warning', 'danger', 'secondary'].includes(this.toast.color)) this.color = this.toast.color
  }
}
</script>

<style lang="scss">
.n-toast {

  .toast-box {
    margin-top: -1px;
    padding: 0 10px;
    display: flex;
    height: 60px;
    line-height: 60px;

    .toast-icon {
      margin-left: 10px;

      .b-icon {
        vertical-align: middle !important;
        font-size: 30px;
      }
    }

    &.toast-primary {
      background: #3aa0ff;
      color: #ffffff;
    }

    &.toast-success {
      background: #3E8914;
      color: #ffffff;
    }

    &.toast-warning {
      background: #F77F00;
      color: #ffffff;
    }

    &.toast-danger {
      background: #D90429;
      color: #ffffff;
    }

    &.toast-secondary {
      background: #EDF2F4;
      color: #003566;
    }
  }
}
</style>
