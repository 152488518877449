import Vue from 'vue'
import VueRouter from 'vue-router'
import {beforeEach} from "@/router/beforeEach";
import {afterEach} from "@/router/afterEach";
import panelRoutes from './routes'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "template" */ '../views/Template.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import(/* webpackChunkName: "home" */ '../views/panel/Home.vue'),
            },
            ...panelRoutes
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '*',
        name: 'error-404',
        component: () => import(/* webpackChunkName: "error-404" */ '../views/error404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(beforeEach)

export default router

router.afterEach(afterEach)
