import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import prototype from './prototype'

import './assets/styles/app.scss'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.component('date-picker', VuePersianDatetimePicker);

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

Vue.prototype.$n = prototype

import Vuelidate from 'vuelidate'
import setTitle from "@/mixins/setTitle";

Vue.use(Vuelidate)

new Vue({
    router,
    store,
    mixins: [setTitle],
    render: h => h(App)
}).$mount('#app')
