export default {
    path: '/prints',
    component: () => import(/* webpackChunkName: "prints" */ '../../views/panel/Prints'),
    children: [
        {
            path: '/',
            name: 'PrintsList',
            meta: {
                'title': 'لیست راهنما'
            },
            component: () => import('../../views/panel/Prints/List.vue'),
        }
    ]
}
