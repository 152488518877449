export default function (permissions) {
    const user = JSON.parse(localStorage.getItem('user'))
    const all_permissions = user.permissions ? user.permissions : []
    if (Array.isArray(permissions)) {
        let has_permission = true
        permissions.forEach(p => {
            has_permission = has_permission && all_permissions.indexOf(p) !== -1
        })
        return has_permission
    } else return all_permissions.indexOf(permissions) !== -1
}
