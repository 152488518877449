import axios from 'axios';
import store from "../store";

export const baseUrl = () => {
    if (process.env.NODE_ENV === 'production') return 'https://n313group.ir/api'
    // return 'https://n313group.ir/api'
    return 'http://localhost:8000/api'
    // return 'http://192.168.1.127:8000/api'
}

const http = axios.create({
    baseURL: baseUrl(),
    // headers: {
    //     'Content-type': 'application/json',
    //     'Authorization': store.state.auth.token !== null ? `Bearer ${store.state.auth.token}` : null
    // }
});

// Define a variable to store pending requests
let pendingRequests = [];

// Define a function to cancel pending requests
const cancelPendingRequests = (config) => {
    pendingRequests.forEach((request, index) => {
        if (request.url === config.url && request.method === config.method) {
            request.cancel('Duplicate request detected');
            pendingRequests.splice(index, 1);
        }
    });
};

http.interceptors.request.use((config) => {
    // Cancel any pending requests with the same URL and method
    cancelPendingRequests(config);

    // Add the request to the pending requests array
    config.cancelToken = new axios.CancelToken((cancel) => {
        pendingRequests.push({ url: config.url, method: config.method, cancel });
    });

    if (config.method !== "get") store.dispatch('showLoading')
    if (config.data && !(config.data instanceof FormData)) config.headers.setContentType('application/json')
    config.headers.setAuthorization(store.state.auth.token !== null ? `Bearer ${store.state.auth.token}` : null)
    return config
})

http.interceptors.response.use(
    (response) => {
        store.dispatch('hideLoading')

        // Remove the completed request from the pending requests array
        pendingRequests = pendingRequests.filter((request) => {
            return request.url !== response.config.url || request.method !== response.config.method;
        });

        return response
    }, (error) => {
        // Remove the failed request from the pending requests array
        if (error.code && error.code !== 'ERR_CANCELED') {
            pendingRequests = pendingRequests.filter((request) => {
                return request.url !== error.config.url || request.method !== error.config.method;
            });
        }

        store.dispatch('hideLoading')
        if (error.response && error.response.status && error.response.status === 401) {
            store.dispatch('auth/logout')
        }
        // else throw error;
        else return Promise.reject(error);
    }
);

export default http
