export default {
    path: '/families',
    component: () => import(/* webpackChunkName: "families" */ '../../views/panel/Families'),
    children: [
        {
            path: '/',
            name: 'FamiliesList',
            meta: {
                'title': 'لیست خانواده ها'
            },
            component: () => import('../../views/panel/Families/List.vue'),
        },
        {
            path: 'insert',
            name: 'FamilyInsert',
            meta: {
                'title': 'افزودن خانواده جدید'
            },
            component: () => import('../../views/panel/Families/Insert.vue'),
        },
        {
            path: ':id',
            component: () => import('../../views/panel/Families/Profile/index.vue'),
            children: [
                {
                    path: '/',
                    name: 'FamilyProfile',
                    meta: {
                        'title': 'پروفایل خانواده'
                    },
                    component: () => import('../../views/panel/Families/Profile/Profile.vue'),
                },
                {
                    path: 'edit',
                    name: 'EditFamily',
                    meta: {
                        'title': 'ویرایش خانواده'
                    },
                    component: () => import('../../views/panel/Families/Edit.vue'),
                },
                {
                    path: 'users',
                    name: 'FamilyUsersList',
                    meta: {
                        'title': 'اعضای خانواده'
                    },
                    component: () => import('../../views/panel/Families/Profile/Users.vue'),
                },
            ]
        },
    ]
}
