export default () => ({
    loading: true,
    contentBox: {
        endScroll: false
    },
    navbar: {
        title: '',
        actions: {
            right: [],
            left: []
        }
    },
    returnTo: null,
    toasts: []
})
