export default {
    updateUser({commit}, user) {
        commit('SET_USER', user)
    },
    userLogin({commit}, response) {
        commit('SET_TOKEN', response.token)
        commit('SET_USER', response.user)
    },
    loadAuthFromLocalStorage({commit}) {
        const token = localStorage.getItem('token') ?? null
        commit('SET_TOKEN', token)

        const user = localStorage.getItem('user') ?? null
        commit('SET_USER', typeof user == 'string' ? JSON.parse(user) : user)
    },
    logout({commit}) {
        commit('LOGOUT')
    }
}
