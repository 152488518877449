export default {
    path: '/users',
    component: () => import(/* webpackChunkName: "users" */ '../../views/panel/Users'),
    children: [
        {
            path: '/',
            name: 'UsersList',
            meta: {
                'title': 'لیست اعضا'
            },
            component: () => import('../../views/panel/Users/List.vue'),
        },
        {
            path: 'insert',
            name: 'UserInsert',
            meta: {
                'title': 'افزودن عضو جدید'
            },
            component: () => import('../../views/panel/Users/Insert.vue'),
        },
        {
            path: ':id/edit',
            name: 'EditUser',
            meta: {
                'title': 'ویرایش عضو'
            },
            component: () => import('../../views/panel/Users/Edit.vue'),
        },
        {
            path: ':id',
            component: () => import('../../views/panel/Users/Profile/index.vue'),
            children: [
                {
                    path: '/',
                    name: 'UserProfile',
                    meta: {
                        'title': 'پروفایل عضو'
                    },
                    component: () => import('../../views/panel/Users/Profile/Profile.vue'),
                },
                {
                    path: 'transactions',
                    name: 'UserTransactionsList',
                    meta: {
                        'title': 'پرداخت های عضو'
                    },
                    component: () => import('../../views/panel/Users/Profile/Transactions.vue'),
                },
                {
                    path: 'families',
                    name: 'UserFamiliesList',
                    meta: {
                        'title': 'خانواده عضو'
                    },
                    component: () => import('../../views/panel/Users/Profile/Families.vue'),
                },
                {
                    path: 'documents',
                    name: 'UserDocuments',
                    meta: {
                        'title': 'مدارک عضو'
                    },
                    component: () => import('../../views/panel/Users/Profile/Documents.vue'),
                },
                {
                    path: 'contact',
                    name: 'UserContact',
                    meta: {
                        'title': 'اطلاعات تماس عضو'
                    },
                    component: () => import('../../views/panel/Users/Profile/Contact.vue'),
                },
            ]
        },
    ]
}
