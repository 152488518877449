export default {
    path: '/profile',
    component: () => import('../../views/panel/Profile/index.vue'),
    children: [
        {
            path: '/',
            name: 'Profile',
            meta: {
                'title': 'پروفایل کاربر'
            },
            component: () => import('../../views/panel/Profile/Profile.vue'),
        },
        {
            path: 'edit',
            name: 'EditProfile',
            meta: {
                'title': 'پروفایل کاربر'
            },
            component: () => import('../../views/panel/Profile/Edit.vue'),
        },
        {
            path: 'transactions',
            name: 'ProfileTransactionsList',
            meta: {
                'title': 'پرداخت های کاربر'
            },
            component: () => import('../../views/panel/Profile/Transactions.vue'),
        },
        {
            path: 'families',
            name: 'ProfileFamiliesList',
            meta: {
                'title': 'خانواده کاربر'
            },
            component: () => import('../../views/panel/Profile/Families.vue'),
        },
    ]
}
