<template>
  <div id="app" :class="{'loading': $store.state.loading}">
    <div class="loading-box" v-if="$store.state.loading">
      <b-icon icon="circle-fill" animation="throb" font-scale="4"></b-icon>
    </div>

    <div class="toasts">
      <transition-group name="stretch-down">
        <template v-for="(toast, toast_index) in toasts">
          <n-toast :key="`toast-${toast_index}`" :toast="toast"/>
        </template>
      </transition-group>
    </div>

    <router-view v-if="isMobile"/>

    <disable-desktop v-else/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NToast from "@/components/NToast";
import {getProfile} from "./http/requests/profile";
import DisableDesktop from "@/components/DisableDesktop";

export default {
  components: {DisableDesktop, NToast},
  metaInfo() {
    return {
      titleTemplate(titleChunk) {
        const suffix = 'ناصرین';
        return titleChunk ? `${titleChunk} | ${suffix}` : suffix;
      },
    };
  },
  data() {
    return {
      isMobile: false
    }
  },
  created() {
    this.getProfile()
    this.checkIsMobile()
  },
  computed: {
    ...mapGetters({toasts: 'getToasts'})
  },
  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
      setPageTitle: 'setPageTitle',
      clearNavbarRightActions: 'clearNavbarRightActions',
      clearNavbarLeftActions: 'clearNavbarLeftActions',
      resetScroll: 'resetScroll'
    }),
    getProfile() {
      getProfile().then(response => {
        this.updateUser(response.data.data)
      }).catch(() => {

      })
    },
    checkIsMobile() {
      this.isMobile = process.env.NODE_ENV !== 'production' || (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      // this.isMobile = true
    }
  },
  watch: {
    '$route': {
      handler(val) {
        this.setPageTitle(val.meta.title ?? null)
        this.clearNavbarRightActions()
        this.clearNavbarLeftActions()
        this.resetScroll()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  direction: rtl;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;

  &.loading {
    overflow: hidden;
    padding: 1px;
  }

  .loading-box {
    transition: all .3s ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--body-bg);
    z-index: var(--z-level-5);
  }

  .toasts {
    z-index: var(--z-level-4);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: fit-content;
  }
}
</style>
