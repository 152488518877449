export default {
    // loading
    SHOW_LOADING(state) {
        state.loading = true
    },
    HIDE_LOADING(state) {
        state.loading = false
    },

    // content box
    END_CONTENT_BOX_SCROLL(state) {
        state.contentBox.endScroll = true
    },
    RESET_CONTENT_BOX_SCROLL(state) {
        state.contentBox.endScroll = false
    },

    // navbar
    SET_PAGE_TITLE(state, title) {
        state.navbar.title = title
    },
    SET_NAVBAR_ACTIONS(state, payload) {
        state.navbar.actions[payload.side] = payload.actions
    },
    CLEAR_NAVBAR_ACTIONS(state, side) {
        state.navbar.actions[side] = []
    },

    // return to
    SET_RETURN_ROUTE(state, route) {
        state.returnTo = route
    },
    UNSET_RETURN_ROUTE(state) {
        state.returnTo = null
    },

    // toasts
    ADD_TOAST(state, toast) {
        state.toasts.push(toast)
    },
    REMOVE_TOAST(state) {
        state.toasts.shift()
    }
}
