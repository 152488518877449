export default {
    path: '/religious-leaders',
    component: () => import(/* webpackChunkName: "religious-leaders" */ '../../views/panel/ReligiousLeaders'),
    children: [
        {
            path: '/',
            name: 'ReligiousLeadersList',
            meta: {
                'title': 'لیست مراجع تقلید'
            },
            component: () => import('../../views/panel/ReligiousLeaders/List.vue'),
        },
        {
            path: 'insert',
            name: 'ReligiousLeaderInsert',
            meta: {
                'title': 'افزودن مرجع تقلید جدید'
            },
            component: () => import('../../views/panel/ReligiousLeaders/Insert.vue'),
        },
        {
            path: ':id',
            component: () => import('../../views/panel/ReligiousLeaders/Profile/index.vue'),
            children: [
                {
                    path: '/',
                    name: 'ReligiousLeaderProfile',
                    meta: {
                        'title': 'پروفایل مرجع تقلید'
                    },
                    component: () => import('../../views/panel/ReligiousLeaders/Profile/Profile.vue'),
                },
                {
                    path: 'edit',
                    name: 'EditReligiousLeader',
                    meta: {
                        'title': 'ویرایش مرجع تقلید'
                    },
                    component: () => import('../../views/panel/ReligiousLeaders/Edit.vue'),
                },
                {
                    path: 'users',
                    name: 'ReligiousLeaderUsersList',
                    meta: {
                        'title': 'مقلدین مرجع تقلید'
                    },
                    component: () => import('../../views/panel/ReligiousLeaders/Profile/Users.vue'),
                },
            ]
        },
    ]
}
