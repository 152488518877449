export default {
    // loading
    showLoading({commit}) {
        commit('SHOW_LOADING')
    },
    hideLoading({commit}) {
        commit('HIDE_LOADING')
    },

    // content box
    endScroll({commit}) {
        commit('END_CONTENT_BOX_SCROLL')
    },
    resetScroll({commit}) {
        commit('RESET_CONTENT_BOX_SCROLL')
    },

    // navbar
    setPageTitle({commit}, title) {
        commit('SET_PAGE_TITLE', title)
    },
    setNavbarRightActions({commit}, actions) {
        commit('SET_NAVBAR_ACTIONS', {side: 'right', actions});
    },
    clearNavbarRightActions({commit}) {
        commit('CLEAR_NAVBAR_ACTIONS', 'right');
    },
    setNavbarLeftActions({commit}, actions) {
        commit('SET_NAVBAR_ACTIONS', {side: 'left', actions});
    },
    clearNavbarLeftActions({commit}) {
        commit('CLEAR_NAVBAR_ACTIONS', 'left');
    },

    // return to
    setReturnRoute({commit}, route) {
        commit('SET_RETURN_ROUTE', route)
    },
    unsetReturnRoute({commit}) {
        commit('UNSET_RETURN_ROUTE')
    },

    // toasts
    addToast({commit}, toast) {
        let timer = toast.time ? toast.time : 1
        timer *= 1000
        commit('ADD_TOAST', toast)
        setTimeout(() => {
            commit('REMOVE_TOAST')
        }, timer)
    }
}
