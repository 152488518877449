export default {
    // profile
    setProfile({commit}, profile) {
        commit('SET_PROFILE', profile)
    },
    clearProfile({commit}) {
        commit('CLEAR_PROFILE')
    },

    // users
    setUserProfile({commit}, user) {
        commit('SET_USER_PROFILE', user)
    },
    clearUserProfile({commit}, userId) {
        commit('CLEAR_USER_PROFILE', userId)
    },

    // groups
    setGroupProfile({commit}, group) {
        commit('SET_GROUP_PROFILE', group)
    },
    clearGroupProfile({commit}, groupId) {
        commit('CLEAR_GROUP_PROFILE', groupId)
    },

    // families
    setFamilyProfile({commit}, family) {
        commit('SET_FAMILY_PROFILE', family)
    },
    clearFamilyProfile({commit}, familyId) {
        commit('CLEAR_FAMILY_PROFILE', familyId)
    },

    // religiousLeaders
    setReligiousLeaderProfile({commit}, religiousLeader) {
        commit('SET_RELIGIOUS_LEADER_PROFILE', religiousLeader)
    },
    clearReligiousLeaderProfile({commit}, religiousLeaderId) {
        commit('CLEAR_RELIGIOUS_LEADER_PROFILE', religiousLeaderId)
    },

    // transactions
    setTransactionProfile({commit}, transaction) {
        commit('SET_TRANSACTION_PROFILE', transaction)
    },
    clearTransactionProfile({commit}, transactionId) {
        commit('CLEAR_TRANSACTION_PROFILE', transactionId)
    }
}
