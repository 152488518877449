export default {
    // profile
    SET_PROFILE(state, profile) {
        state.profile = profile
    },
    CLEAR_PROFILE(state) {
        if (state.profile) state.profile = null
    },

    // users
    SET_USER_PROFILE(state, user) {
        state.users[user.id] = user
    },
    CLEAR_USER_PROFILE(state, userId) {
        if (state.users[userId]) delete state.users[userId]
    },

    // groups
    SET_GROUP_PROFILE(state, group) {
        state.groups[group.id] = group
    },
    CLEAR_GROUP_PROFILE(state, groupId) {
        if (state.groups[groupId]) delete state.groups[groupId]
    },

    // families
    SET_FAMILY_PROFILE(state, family) {
        state.families[family.id] = family
    },
    CLEAR_FAMILY_PROFILE(state, familyId) {
        if (state.families[familyId]) delete state.families[familyId]
    },

    // families
    SET_RELIGIOUS_LEADER_PROFILE(state, religiousLeader) {
        state.religiousLeaders[religiousLeader.id] = religiousLeader
    },
    CLEAR_RELIGIOUS_LEADER_PROFILE(state, religiousLeaderId) {
        if (state.religiousLeaders[religiousLeaderId]) delete state.religiousLeaders[religiousLeaderId]
    },

    // transactions
    SET_TRANSACTION_PROFILE(state, transaction) {
        state.transactions[transaction.id] = transaction
    },
    CLEAR_TRANSACTION_PROFILE(state, transactionId) {
        if (state.transactions[transactionId]) delete state.transactions[transactionId]
    }
}
