import groups from "@/router/routes/groups";
import users from "@/router/routes/users";
import profile from "@/router/routes/profile";
import religiousLeaders from "@/router/routes/religiousLeaders";
import families from "@/router/routes/families";
import transactions from "@/router/routes/transactions";
import logs from "@/router/routes/logs";
import prints from "@/router/routes/prints";

export default [
    transactions,
    religiousLeaders,
    groups,
    families,
    users,
    profile,
    prints,
    logs
]
