export default {
    path: '/groups',
    component: () => import(/* webpackChunkName: "groups" */ '../../views/panel/Groups'),
    children: [
        {
            path: '/',
            name: 'GroupsList',
            meta: {
                'title': 'لیست گروه ها'
            },
            component: () => import('../../views/panel/Groups/List.vue'),
        },
        {
            path: 'insert',
            name: 'GroupInsert',
            meta: {
                'title': 'افزودن گروه جدید'
            },
            component: () => import('../../views/panel/Groups/Insert.vue'),
        },
        {
            path: ':id',
            component: () => import('../../views/panel/Groups/Profile/index.vue'),
            children: [
                {
                    path: '/',
                    name: 'GroupProfile',
                    meta: {
                        'title': 'پروفایل گروه'
                    },
                    component: () => import('../../views/panel/Groups/Profile/Profile.vue'),
                },
                {
                    path: 'edit',
                    name: 'EditGroup',
                    meta: {
                        'title': 'ویرایش گروه'
                    },
                    component: () => import('../../views/panel/Groups/Edit.vue'),
                },
                {
                    path: 'users',
                    name: 'GroupUsersList',
                    meta: {
                        'title': 'اعضای گروه'
                    },
                    component: () => import('../../views/panel/Groups/Profile/Users.vue'),
                },
            ]
        },
    ]
}
